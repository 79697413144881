<template>
  <div
    class="toast-msg__wrapper"
    v-if="displayed"
    :class="{'toast-msg__animated': displayed}"
  >
    {{ msg }}
  </div>
</template>

<script>
export default {
  data() {
    return {msg: "", displayed: false};
  },
  methods: {
    show(v) {
      var instance = this;

      this.msg = v;
      this.displayed = true;

      setTimeout(function () {
        instance.displayed = false;
      }, 4000);
    }
  }
};
</script>

<style lang="scss" scoped>
.toast-msg__wrapper {
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  padding: 11px 32px;
  background: rgba(72, 73, 72, 0.6);
  opacity: 0;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  white-space: pre;
  z-index: 10000;
  border-radius: 32px;
  line-height: 1.25;
  white-space: nowrap;
}
.toast-msg__animated {
  animation: toastPopupAnimation ease 3s;
}
@media (max-width: 767px) {
  .toast-msg__wrapper {
    min-width: 250px;
    white-space: normal;
  }
}
@keyframes toastPopupAnimation {
  0% {
    opacity: 0;
    bottom: 30px;
  }
  8% {
    opacity: 1;
    bottom: 40px;
  }
  92% {
    opacity: 1;
    bottom: 40px;
  }
  100% {
    opacity: 0;
    bottom: 30px;
  }
}
</style>
