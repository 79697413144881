var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.displayed ? _c('div', {
    staticClass: "toast-msg__wrapper",
    class: {
      'toast-msg__animated': _vm.displayed
    }
  }, [_vm._v(" " + _vm._s(_vm.msg) + " ")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }